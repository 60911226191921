import { ReactElement, useState } from "react";
import AccordionItem from "@components/shared/accordionItem";
import styles from "./faq.module.scss";
export default function FAQ(): ReactElement {
    const [activeTab, setActiveTab] = useState(-1);

    const clickHandler = (idx: number) => {
        if (idx === activeTab) {
            setActiveTab(-1);
            return;
        }
        setActiveTab(idx);
    };

    return (
        <div className={`${styles["antivirus-gray-bg"]}`}>
            <section className={`pt-12 px-4 py-2 text-left text-base`}>
                <div className="md:flex  w-full flex-column mb-8">
                    <div className="md:w-6/12 md:pr-6 mb-6">
                        <h2 className="text-xl font-bold mb-4">
                            What is a website builder?
                        </h2>
                        <p>
                            A website builder is a great resource for those who
                            lack coding expertise or the budget to hire a
                            developer. This tool gives customers the ability to
                            create attractive websites easily, be they for
                            personal or eCommerce use. A website builder
                            streamlines the website development process by
                            offering a variety of templates and user-friendly
                            tools, ensuring an appealing and interactive design
                            without requiring technical expertise.
                        </p>
                    </div>
                    <div className="md:w-6/12 md:pl-6 ">
                        <h2 className="text-xl font-bold mb-4">
                            Why do I need a website builder?
                        </h2>
                        <p>
                            A website builder is essential for individuals
                            looking to establish a strong online presence.
                            Firstly, utilizing a website builder's services is
                            affordable compared to hiring a professional
                            developer. Additionally, it is accessible to anyone
                            without coding experience due to its user-friendly
                            design and intuitive capabilities, letting users
                            easily create a professional website. Website
                            builders also save users time and effort by speeding
                            up website creation. Although they are simple, they
                            offer many customization options, allowing users to
                            customize their websites to fit their brand
                            identities.
                        </p>
                    </div>
                </div>
                <div className={`mb-8 pb-16 ${styles.border}`}>
                    <h2 className="text-xl font-bold mb-4">Disclaimer</h2>
                    <div>
                        At <b>WebsiteBuilderSelect</b>, we monitor the best and
                        latest deals to give you the best price available on the
                        market. Although we might receive a commission from
                        referral links, rest assured that none of the listed
                        website builder providers are sponsoring our reviews.{" "}
                        <br />
                        Your website is an important platform for showcasing
                        your work, services, and products, expanding your reach
                        beyond geographical boundaries, and developing a strong
                        online presence. Choosing the appropriate website
                        builder is critical to paving the road to success and
                        attracting new customers. However, sifting through the
                        different website builder services, each claiming to
                        deliver the greatest service, can be difficult. We ease
                        this burden by thoroughly testing and assessing the best
                        website builder tools and providing detailed reviews of
                        their features, plans, and costs.
                    </div>
                </div>
            </section>
            <section className="pt-2 flex text-lg md:text-left md:text-sm px-4">
                <div
                    className={`${styles.border} flex flex-col lg:flex-row pb-16 w-full`}
                >
                    <div className="lg:w-6/12 w-full lg:pr-6">
                        <h2 className="text-3xl font-bold mb-8">FAQs</h2>
                        {questions.map((item, idx) => {
                            return (
                                <div
                                    key={idx}
                                    onClick={() => clickHandler(idx)}
                                >
                                    <AccordionItem
                                        title={item.title}
                                        body={item.body}
                                        isOpen={idx === activeTab}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="lg:w-6/12 pt-10 lg:pt-16 hidden md:flex flex-col lg:pl-6">
                        <br />
                        <p>
                            Your website is your platform to showcase your work,
                            services, or products, and it serves as your gateway
                            to a global audience. Selecting the ideal website
                            builder is pivotal to achieving success and
                            attracting more customers. Yet, in a market flooded
                            with website builder providers all claiming to offer
                            the best service, making the right decision can be
                            challenging. We alleviate this burden by rigorously
                            testing and reviewing the top website builder
                            solutions. Our aim is to guide you through their
                            features, plans, and costs, ensuring you make an
                            informed choice for your online presence.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}
const questions = [
    {
        title: "Can a website builder boost my business's growth?",
        body: "A professionally designed website can substantially help you achieve both personal and professional goals. It broadens your reach, improves brand recognition, and promotes business expansion. A reputable website builder often provides a user-friendly interface and excellent SEO optimization tools, improving your website's likelihood of ranking higher on different search engines.",
    },
    {
        title: "Should I use a free website builder?",
        body: "You can; however, not all free website builders are made equal. Some may have restricted capabilities or lack legitimacy, but others provide useful tools and functionalities. It is critical to thoroughly evaluate the offerings of any free website builder, as they are frequently used as promotional platforms for their premium services.",
    },
    {
        title: "Which website builder is best for small and medium enterprises?",
        body: "All of the website builder solutions listed above provide extremely flexible and convenient website creation tools suitable for a wide range of applications, including business and personal use, as well as catering to businesses of varying sizes.",
    },
];
